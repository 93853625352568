<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">
                                        {{$t("title.additionalServiceDetail")}}
                                    </span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                        <div class="mt-3">
                            <div class="box border rounded p-4">
                                <div class="clearfix">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.title')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.title}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.type')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.additional_service_type ?
                                                detailLoad.additional_service_type.title : ""}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                    <b-row v-show="detailLoad.description">
                                        <b-col md="6" lg="6" sm="12"><h6><span class="text-capitalize">{{$t('title.description')}}</span>
                                        </h6></b-col><!--/.col-->
                                        <b-col md="6" lg="6" sm="12">
                                            <div class="text-right">
                                                {{detailLoad.description}}
                                            </div>
                                        </b-col><!--/.col-->
                                    </b-row><!--/.row-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {request} from "../../util/Request";

    export default {
        props: ['handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            async getDetail(id) {
                await request({
                    url: `/additional/services/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {

                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
